import React, {createContext, useContext, useEffect, useState} from 'react';
import {setCookie, destroyCookie, parseCookies} from 'nookies';
import {useAppMetadataQuery} from '@graphql/generated/graphql';
import {getCountryData} from '@lib/useCountryData';
import {
  COOKIE_OPTIONS,
  DELIVER_TO_COUNTRY,
  DELIVER_TO_ADDRESS,
  DELIVER_TO_LATITUDE,
  DELIVER_TO_LONGITUDE,
  DEFAULT_LOCATIONS,
} from '@lib/helpers/helper';

import myIP from '@lib/myIP';
import delayMilliseconds from '@lib/delayMilliseconds';

interface IGeolocationProviderProps {}
interface IGeolocationContext {
  address: string;
  latitude: number;
  longitude: number;
  loading: boolean;
  permitted: boolean;
  setLocation: (country: string, addr: string, lat: number | string, lng: number | string) => void;
  getCurrentLocation: () => Promise<boolean>;
  reset: () => void;
}

const initialStates: IGeolocationContext = {
  address: '',
  latitude: 0,
  longitude: 0,
  loading: false,
  permitted: true,
  setLocation: () => {},
  getCurrentLocation: async () => false,
  reset: () => {},
};

export const GeolocationContext = createContext<IGeolocationContext>(initialStates);
export const useGeolocation = (): IGeolocationContext => useContext(GeolocationContext);

const GeolocationProvider: React.FC<IGeolocationProviderProps> = ({children}) => {
  const {data} = useAppMetadataQuery();
  const {countryData} = getCountryData(data?.app?.consumerCountry, data?.app?.operatingCountries);

  const [address, setAddress] = useState(initialStates.address);
  const [latitude, setLatitude] = useState(initialStates.latitude);
  const [longitude, setLongitude] = useState(initialStates.longitude);
  const [loading, setLoading] = useState(initialStates.loading);
  const [permitted, setPermitted] = useState(initialStates.permitted);

  const setLocation = (country: string, addr: string, lat: number | string, lng: number | string) => {
    setCookie(null, DELIVER_TO_COUNTRY, country, COOKIE_OPTIONS);
    setCookie(null, DELIVER_TO_ADDRESS, addr, COOKIE_OPTIONS);
    setCookie(null, DELIVER_TO_LATITUDE, lat.toString(), COOKIE_OPTIONS);
    setCookie(null, DELIVER_TO_LONGITUDE, lng.toString(), COOKIE_OPTIONS);
    setAddress(addr);
    setLatitude(+lat);
    setLongitude(+lng);
  };

  const getGeolocationState = async () => {
    const res = await navigator.permissions?.query({name: 'geolocation'});
    setPermitted(res?.state !== 'denied');
    return res?.state;
  };

  const getCachedLocation = () => {
    const parsedCookies = parseCookies();
    const parsedAddress = parsedCookies[DELIVER_TO_ADDRESS];
    const parsedLatitude = +parsedCookies[DELIVER_TO_LATITUDE];
    const parsedLongitude = +parsedCookies[DELIVER_TO_LONGITUDE];
    const hasCoord = parsedLongitude && parsedLongitude;
    if (hasCoord) {
      setAddress(parsedAddress);
      setLatitude(parsedLatitude);
      setLongitude(parsedLongitude);
      return true;
    }
    return false;
  };

  const getDefaultLocation = async () => {
    const consumerCountry = countryData?.country;
    const currentCountry = consumerCountry || (await myIP()).country;
    const defaultCountry = Object.keys(DEFAULT_LOCATIONS)[0];
    const country = DEFAULT_LOCATIONS[currentCountry] ? currentCountry : defaultCountry;
    const {address: addr, lat, lng} = DEFAULT_LOCATIONS[country];
    setLocation(country, addr, lat, lng);
  };

  const getCurrentLocation = async () => {
    return new Promise((resolve: (value: boolean) => void) => {
      setLoading(true);
      navigator.geolocation.getCurrentPosition(
        async pos => {
          const lat = pos.coords.latitude;
          const lng = pos.coords.longitude;
          const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&sensor=true&key=${process.env.GOOGLE_PLACE_API}`;
          const res = await fetch(url);
          const data = await res.json();
          const result = data.results[0];
          if (result) {
            const countryComp = result.address_components.find(c => c.types.includes('country'));
            const country = countryComp?.short_name;
            const formatted_address = result.formatted_address;
            setLocation(country, formatted_address, lat, lng);
            setLoading(false);
            setPermitted(true);
            resolve(true);
          }
        },
        () => {
          setLoading(false);
          setPermitted(false);
          resolve(false);
        },
      );
    });
  };

  useEffect(() => {
    (async () => {
      const state = await getGeolocationState();
      const denied = state !== 'granted';
      await delayMilliseconds(100);
      if (denied) {
        getCachedLocation() || getDefaultLocation();
      } else {
        getCachedLocation() || getCurrentLocation();
      }
    })();
  }, []);

  const reset = () => {
    destroyCookie(null, DELIVER_TO_COUNTRY);
    destroyCookie(null, DELIVER_TO_ADDRESS);
    destroyCookie(null, DELIVER_TO_LATITUDE);
    destroyCookie(null, DELIVER_TO_LONGITUDE);
    setAddress('');
    setLatitude(0);
    setLongitude(0);
  };

  return (
    <GeolocationContext.Provider
      value={{
        address,
        latitude,
        longitude,
        loading,
        permitted,
        setLocation,
        getCurrentLocation,
        reset,
      }}>
      {children}
    </GeolocationContext.Provider>
  );
};

export default GeolocationProvider;
